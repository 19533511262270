import { Capacitor } from '@capacitor/core';
import axios from 'axios';
import store from '@/store';

let $_CoreService_http = {};
let $_CoreService_http_digital_expenses = {};

// const $_CoreService_http_MP = {};

/*
const updateUserToken = async function () {
    return new Promise(async (resolve, reject) => {
        try {
            //const token = await auth.methods.retrieveToken();
            const token = "eyJraWQiOiJISDhVNWx5N1ltckwxTHdBcUhjaGdnM0J2cWNWcmNjbmtLNWpcL29WaW9jaz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJkODc2MzYzNS1kMzA2LTQ3MzgtOTg2NS00NDgzZTExZWE5NjUiLCJhdWQiOiIzdG9uM204MGcyYmhjbGlubWI5MzJsczdlNSIsImV2ZW50X2lkIjoiYjQzYTQ3YzYtMTA0Yi00YmIxLWJmZTAtZjJhNTQ0NzRkZTQ5IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2MTQ5NDg1NDUsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX0NxZ0ZMU253byIsIm5hbWUiOiJqdWFuY2FybG9zK2NvbmZpcm1lZEBpLWRhdGEuY29tLmFyIiwiY29nbml0bzp1c2VybmFtZSI6Imp1YW5jYXJsb3MrY29uZmlybWVkQGktZGF0YS5jb20uYXIiLCJleHAiOjE2MTQ5NTIxNDUsImlhdCI6MTYxNDk0ODU0NSwiZW1haWwiOiJqdWFuY2FybG9zK2NvbmZpcm1lZEBpLWRhdGEuY29tLmFyIn0.LEIHWPM6eTFPYN0iyQerGoiJCHDI_pvYQB1J-GVUo1HEXHFeCcTB7SsCmLpnEVwJCdsYF2RqAn4q9y4gtXuB4AGyQ2bgPBvdpwAFnN1EgrVVT8LVF3y1alw7GKXpIcWvLARPMmFYtkjT3N8idQ-rnxSutSa_F1ooUkhdqbstG67EnadXb2IB9jat7DU0AohyYlmXNSxO2lN45Zr97B7KSZFWDqYbUD1pmZXBZkN-oWETCglUuhGpgtXFajUs-qjul739HG3_gbNJ7Wy7l9R9z6-SnGp2RvoNKsbT9xGYGL-U0WV6rn8pL1DNcaa8DhvVLwhbRwzJpbch2UuLp-tXGw";

            $_CoreService_http.defaults.headers.common['Authorization'] = token;
            resolve(token);

        } catch (err) {
            reject(err);
        }
    });

};
*/

export default {

  created() {
    $_CoreService_http = axios.create(
      {
        baseURL: process.env.VUE_APP_CORE_SERVICE_URL,
        headers:
          {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
      },
    );
    $_CoreService_http_digital_expenses = axios.create(
      {
        baseURL: process.env.VUE_APP_API_SUITE,
        headers:
          {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: process.env.VUE_APP_AUTH_TOKEN,
          },
      },
    );

    /* $_CoreService_http_MP = axios.create(
      {
        baseURL: process.env.VUE_APP_CORE_SERVICE_URL,
        headers:
          {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
      },
    ); */

    $_CoreService_http.interceptors.request.use((config) => {

      if (store.getters.user && store.getters.user.token) {
        config.headers.Authorization = store.getters.user.token;
      }
      return config;
    });

    $_CoreService_http.interceptors.response.use((response) => response);
  },

  methods:
    {
      $_CoreService_getResource(endpoint) {
        /* Paging true: Devuelve un objeto con el paginador y data
        Ejemplo: { paging: {...}, data: { ... }  }

        Paging false: Devuelve directamente el objeto data
        Ejemplo: { (data) } */

        return new Promise(async (resolve, reject) => {
          /* if (!endpoint) {
            return reject('You must provide an endpoint to use this function');
          } */
          $_CoreService_http.get(endpoint).then((result) => {
            resolve(result.data.data);
          }).catch((err) => {
            reject(err);
          });
        });
      },
      $_CoreService_deleteResource(endpoint) {
        /* Paging true: Devuelve un objeto con el paginador y data
          Ejemplo: { paging: {...}, data: { ... }  }

          Paging false: Devuelve directamente el objeto data
          Ejemplo: { (data) } */

        return new Promise(async (resolve, reject) => {
          /* if (!endpoint) {
            return reject('You must provide an endpoint to use this function');
          } */

          try {
            // await updateUserToken();

            const result = await $_CoreService_http.delete(endpoint);

            const resultData = result.data.data;

            resolve(resultData);
          } catch (err) {
            reject(err);
          }
        });
      },
      $_CoreService_getResourceOne(endpoint) {
        return new Promise(async (resolve, reject) => {
          /* if (!endpoint) {
            return reject('You must provide an endpoint to use this function');
          } */

          try {
            // await updateUserToken();
            const result = await $_CoreService_http.get(endpoint);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      $_CoreService_postResource(endpoint, data) {
        return new Promise(async (resolve, reject) => {
          try {
            // await updateUserToken();
            const result = await $_CoreService_http.post(endpoint, data);
            resolve(result.data);
          } catch (err) {
            reject(err);
          }
        });
      },
      $_CoreService_postResource_digitalExpenses(endpoint, data) {
        return new Promise(async (resolve, reject) => {
          try {
            // await updateUserToken();
            const result = await $_CoreService_http_digital_expenses.post(endpoint, data);
            resolve(result.data);
          } catch (err) {
            reject(err);
          }
        });
      },
        $_CoreService_postResource_Expensas_pagas(endpoint) {
            return new Promise(async (resolve, reject) => {
                try {
                    // await updateUserToken();
                    const result = await $_CoreService_http.post(endpoint);
                    resolve(result.data);
                } catch (err) {
                    reject(err);
                }
            });
        },
      $_CoreService_postResourceMP(endpoint, data) {
        return new Promise(async (resolve, reject) => {
          try {
            // await updateUserToken();
            const result = await $_CoreService_http.post(endpoint, data);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      $_CoreService_postResourceCheckMP(endpoint, data) {
        return new Promise(async (resolve, reject) => {
          try {
            // await updateUserToken();
            const result = await $_CoreService_http.post(endpoint, data);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      $_CoreService_postBinary(endpoint, data_binary, contentType = 'image/jpeg') {
        return new Promise(async (resolve, reject) => {
          /* if (!endpoint) {
            return reject('You must provide an endpoint to use this function');
            return reject('You must provide an endpoint to use this function');
          } */

          try {
            const { token } = this.$store.state;

            const result = await axios(
              {
                method: 'POST',
                headers:
                  {
                    Authorization: token,
                    'Content-Type': 'application/json',
                    'type-file': contentType,
                  },
                data: data_binary,
                url: `${process.env.VUE_APP_API_SERVICE_URL}${endpoint}`,
              },
            );
            resolve(result.data);
          } catch (err) {
            reject(err);
          }
        });
      },
      $_CoreService_postBinaryPayment(data_binary, contentType = 'image/jpeg', id) {
        return new Promise(async (resolve, reject) => {
          const { token } = this.$store.state;
          await axios(
            {
              method: 'POST',
              headers:
                  {
                    Authorization: token,
                    'Content-Type': 'application/json',
                    'type-file': contentType,
                    notification: id,
                  },
              data: data_binary,
              baseURL: `${process.env.VUE_APP_API_SERVICE_URL}/notificationPayment`,
            },
          ).then((response) => {
            resolve(response.data);
          }).catch((e) => reject(e));
        });
      },
      $_CoreService_patchResource(endpoint, data) {
        return new Promise(async (resolve, reject) => {
          /* if (!endpoint) {
            return reject('You must provide an endpoint to use this function');
          } */

          try {
            // await updateUserToken();

            const result = await $_CoreService_http.patch(endpoint, data);

            resolve(result.data);
          } catch (err) {
            reject(err);
          }
        });
      },
      $_CoreService_postSenRequestApiPower(endpoint, data) {
        return new Promise(async (resolve, reject) => {
          const ruta = process.env.VUE_APP_API_SERVICE_NO_USER_URL;
          // eslint-disable-next-line global-require
          const jwt = require('jsonwebtoken');

          const token = (jwt.sign({
            iat: Math.floor(Date.now() / 1000),
            exp: Math.floor(Date.now() / 1000) + (60 * 60),
            data,
          }, 'idata_power_11!34_///1920+w_aws'));
          await axios.post(
            ruta + endpoint,
            data,
            {
              headers:
                {
                  Authorization: token,
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
            },
          ).then((response) => {
            resolve(response);
          }).catch((e) => {
            reject(e);
          });
        });
      },
      getPushTokens() {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResource('/me/push_tokens');
            const tokens = result.map((o) => o.fcm_token);
            resolve(tokens);
          } catch (err) {
            reject(err);
          }
        });
      },
      refreshToken() {
        return new Promise(async (resolve, reject) => {
          this.$_CoreService_postResource('/refreshToken', {
            token: store.getters.user.token,
          }).then((result) => {
            resolve(result);
          }).catch((err) => { reject(err); });
        });
      },
      subscribePush(token) {
          if ((Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android')) {

              return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_postResource('/me/push_tokens',
              {
                fcm_token: token,
              });

            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
          }
      },
      unSubscribePush(token) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_postResource('/me/erase_push_tokens',
              {
                fcm_token: token,
              });

            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getDocuments(oa_id, type = 'all', page = 1, period) {
        return new Promise(async (resolve, reject) => {
          try {
            let result;
              // eslint-disable-next-line no-empty
              console.log(`/owners_associations/${oa_id}/document_types/${type}/documents?page=${page}`);
              if (period) {

                  result = await this.$_CoreService_getResource(
                      `/owners_associations/${oa_id}/document_types/${type}/documents?page=${page}`,
                      true,
                  );

              } else {
 result = await this.$_CoreService_getResource(
              `/owners_associations/${oa_id}/document_types/${type}/documents?page=${page}`,
              true,
            ); 
}
            console.log(result);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
getDocumentsAlt(oa_id, type = 'all', page = 1, period) {
        return new Promise(async (resolve, reject) => {
          try {
            let result;
              // eslint-disable-next-line no-empty
              console.log(`/owners_associations/${oa_id}/document_type/${type}/documents?page=${page}`);
              if (period) {

                  result = await this.$_CoreService_getResource(
                      `/owners_associations/${oa_id}/document_type/${type}/documents?page=${page}`,
                      true,
                  );

              } else {
 result = await this.$_CoreService_getResource(
              `/owners_associations/${oa_id}/document_type/${type}/documents?page=${page}`,
              true,
            ); 
}

            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getNewsDetail(id) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResource(`/news/${id}`);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getDocumentDetail(id) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResource(`/documents/${id}`);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getNotificationsPaymentsDetail(id) {
        return new Promise(async (resolve, reject) => {
          this.$_CoreService_getResource(`/payment_notifications/${id}`).then((result) => {
            resolve(result);
          }).catch((err) => {
            reject(err);
          });
        });
      },
      getNews(owners_association_id, page = 1, limit = 150) {
        return new Promise((resolve, reject) => {
          /* if (!owners_association_id) {
            return reject(true);
          } */
          this.$_CoreService_getResource(
            `/owners_associations/${owners_association_id}/news?sort=created_at&direction=desc&page=${page}&limit=${limit}`,
          ).then((response) => {
            const newsUniqueArray = response.reduce((accumulator, current) => {
              function checkIfAlreadyExist(currentVal) {
                return accumulator.some((item) => (item.id === currentVal.id));
              }
              if (checkIfAlreadyExist(current)) {
                return accumulator;
              }
              return [...accumulator, current];
            }, []);
            resolve(newsUniqueArray);
          }).catch((e) => { reject(e); });
        });
      },
      updateProfile(data) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_patchResource('/me', data);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getProfile() {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResource('/me');
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getWelcome() {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResourceOne('/me/welcome');
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      sendRequestSuite(data) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_postResource('/me/sendRequest', data);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      sendRequestApiPower(data) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_postSenRequestApiPower('/sendRequest', data);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getOwnersAssociations() {
        return new Promise(async (resolve, reject) => {
          try {
            resolve(await this.$_CoreService_getResource('/me/owners_associations/'));
          } catch (err) {
            reject(err);
          }
        });
      },
      getOwnersAssociation(owners_association_id) {
        return new Promise((resolve, reject) => {
          this.$_CoreService_getResource(`/owners_associations/${owners_association_id}`).then((res) => {
            resolve(res);
          }).catch((e) => {
            reject(e);
          });
        });
      },
      getConfirmVouchers(owners_association_id) {
        return new Promise((resolve, reject) => {
          this.$_CoreService_getResource(`/owners_associations/${owners_association_id}/interactive_invoices`).then((res) => {
            resolve(res);
          }).catch((e) => {
            reject(e);
          });
        });
      },
      getVouchers(owners_association_id, date) {
        return new Promise((resolve, reject) => {
            console.log(`/owners_associations/${owners_association_id}/interactive_invoices/${date}`);
          this.$_CoreService_getResource(`/owners_associations/${owners_association_id}/interactive_invoices/${date}`).then((res) => {
            resolve(res);
          }).catch((e) => {
            reject(e);
          });
        });
      },
      getAdministrationFeatures(administration_id) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResourceOne(`/administrations/${administration_id}/features`);
            resolve(result.data);
          } catch (err) {
            reject(err);
          }
        });
      },
        getInteractiveInvoices(oa_id, period_id) {
            return new Promise((resolve, reject) => {
                console.log(`/owners_associations/${oa_id}/interactive_invoices/${period_id}`);
                this.$_CoreService_getResource(`/owners_associations/${oa_id}/interactive_invoices/${period_id}`).then((res) => {
                    resolve(res);
                }).catch((e) => { reject(e); });
            });
            },
      getSettlements(oa_id, period_id) {
        return new Promise((resolve, reject) => {
          this.$_CoreService_getResource(`/me/owners_associations/${oa_id}/periods/${period_id}/settlements`).then((res) => {
            resolve(res);
          }).catch((e) => { reject(e); });
        });
      },
      getSettlementsSummary(oa_id, period_id) {
        return new Promise(async (resolve, reject) => {
          this.$_CoreService_getResource(`/me/owners_associations/${oa_id}/periods/${period_id}/settlements/summary`).then((response) => {
            resolve(response);
          }).catch((e) => { reject(e); });
        });
      },
      getFunctionalUnits(owners_association_id) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResource(`/me/owners_associations/${owners_association_id}/functional_units`);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getPaymentMethods() {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResource('/payment_methods');
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      notifyPayment(payment) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_postResource('/payment_notifications', payment);
            resolve(result.data.id);
            console.log(result.data);
          } catch (err) {
            reject(err);
          }
        });
      },
      getBankAccounts() {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResource('/payment_methods');
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      sendMessage(message, administration_id) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_postResource(`/administrations/${administration_id}/messages`, message);
            resolve(result.data);
          } catch (err) {
            reject(err);
          }
        });
      },
      getMessages() {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResource('/messages?sort=created_at');
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getMessagesByAdministration(administration_id) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResource(`/administrations/${administration_id}/messages?sort=created_at&direction=desc`);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      uploadChatImage(binary_content, mimetype) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_postBinary('/messages/upload_image', binary_content, mimetype);
            resolve(result.data);
          } catch (err) {
            reject(err);
          }
        });
      },
      uploadChatAttachment(binary_content, mimetype) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_postBinary('/messages/upload_attachment', binary_content, mimetype);
            resolve(result.data);
          } catch (err) {
            reject(err);
          }
        });
      },
      uploadPaymentNotifications(binary_content, mimetype, id) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_postBinaryPayment(binary_content, mimetype, id);
            resolve(result.data.url);
          } catch (err) {
            reject(err);
          }
        });
      },
      getAmenities(owners_association_id) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResource(`/owners_associations/${owners_association_id}/amenities`);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getPaymentNotifications() {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResource('/payment_notifications?sort=created_at&direction=desc&page=1&limit=12');
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getAmenity(amenity_id) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResource(`/amenities/${amenity_id}`);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getMonthsAvailable(amenityId) {
        return new Promise(async (resolve, reject) => {
          try {
            // eslint-disable-next-line
            const result = await this.$_CoreService_getResource('/amenities/'+amenityId+'/getAvailableMonths');
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getDaysAvailable(amenityId, month) {
        return new Promise(async (resolve, reject) => {
          try {
            // eslint-disable-next-line
            const result = await this.$_CoreService_getResource('/amenities/'+amenityId+'/getAvailableDays/'+month);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getBookingSlotsAvailable(amenityId, day) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResource(`/amenities/${amenityId}/getAvailableHours/${day}`);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      getAmenityBookings(ownersAssociationId) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_getResource(`/owners_associations/${ownersAssociationId}/bookings`);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
      bookAmenity(amenity_id, data) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_postResource(`/amenities/${amenity_id}/book_request`, data);
            resolve(result.data);
          } catch (err) {
            reject(err);
          }
        });
      },
      cancelBooking(ownersAssociationId, book_id) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_deleteResource(`/owners_associations/${ownersAssociationId}/bookings/delete/${book_id}`);
            resolve(result.data);
          } catch (err) {
            reject(err);
          }
        });
      },
      newCancelBooking(amenityId, formBody) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_postResource(`/amenities/${amenityId}/cancel_booking`, formBody);
            resolve(result);
          } catch (err) {
            if (err.response) {
              reject(err.response);
            }
          }
        });
      },
      initializeMercadoPagoPaymentFlow(ownersAssociationId, data) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_postResourceMP(`/owners_associations/${ownersAssociationId}/payments/initializeMercadoPagoPaymentFlow`, data);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        });
      },
     /* initializeExpensasPagasPaymentFlow(ownersAssociationId, functionalUnitId, data) {

          return new Promise(async (resolve, reject) => {
              const realData = { ...data, functional_unit_id: functionalUnitId };
              console.log(JSON.stringify(realData));
              try {
                  const result = await this.$_CoreService_postResource(`/owners_associations/${ownersAssociationId}/expense_payments/intentionToPay`, realData);
                  resolve(result);
              } catch (err) {
                  reject(err);
              }

            });
        }, */
        initializeExpensasPagasPaymentFlow(ownersAssociationId, data) {

            return new Promise(async (resolve, reject) => {
               try {
                    const result = await this.$_CoreService_postResource(`/owners_associations/${ownersAssociationId}/expense_payments/multiplePaymentIntents`, data);
                    resolve(result);
                } catch (err) {
                    reject(err);
                }

            });
        },
      checkMarketPlacePayment(data) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await this.$_CoreService_postResourceCheckMP('/payments/checkMarketPlacePayment', data);
            resolve(result.data);
          } catch (err) {
            reject(err);
          }
        });
      },
      suscribeDigitalExpenses(data) {
        return new Promise(async (resolve, reject) => {
          await this.$_CoreService_postResource_digitalExpenses('/v1/api_users/send-suscription-email', data).then((result) => {
            console.log('result:', result);
            resolve(result);
          }).catch((e) => reject(e));
        });
      },
      checkQRSuscriptor(data) {
        return new Promise(async (resolve, reject) => {
          await this.$_CoreService_postResource_digitalExpenses('/v1/api_users/check-qr-suscriptor', { settlement_id: data }).then((result) => {
            console.log('result:', result);
            resolve(result);
          }).catch((e) => reject(e));
        });
      },
      checkQRSuscriptorPromo(settlementId, email) {
        return new Promise(async (resolve, reject) => {
          await this.$_CoreService_postResource_digitalExpenses('/v1/api_users/check-qr-suscriptor',
              { settlement_id: settlementId, email }).then((result) => {
            console.log('result:', result);
            resolve(result);
          }).catch((e) => reject(e));
        });
      },
      reportExpensasPagasPayment(ownersAssociationId, hash) {
        return new Promise(async (resolve, reject) => {
            try {
              const result = await this.$_CoreService_postResource(`/owners_associations/${ownersAssociationId}/expense_payments/updatePaymentData`, { hash });
              resolve(result);
            } catch (err) {
              reject(err);
            }
          });
      },

        checkExpensasPagasPayment(ownersAssociationId) {
            return new Promise(async (resolve, reject) => {
                try {
                    const result = await this.$_CoreService_postResource(`/owners_associations/${ownersAssociationId}/expense_payments/updatePaymentsData`, '');
                    resolve(result);
                } catch (err) {
                    reject(err);
                }
            });
        },
    },
};
