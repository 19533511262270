<template>
  <div class="page-account-view page-owners-associations-payment-notification page-owners-associations-overview">
    <header-back v-if="is_capacitor" title=" VOLVER" />
    <section class="bar theme-blue">
      <div class="container">
        <div class="bar-avatar">
          <span><img src="@/assets/img/notificacion_de_pago_details.svg" class="img_min"></span>
        </div>
        <h3 class="bar-firm-name">
          {{ title }}
        </h3>
        <br />
      </div>
    </section>
    <default-modal v-if="showModal" style="z-index: 10000">
      <div slot="header">
        <div class="col-12" style="padding: 2rem 1rem">
          <h4>Realizar pago</h4>
        </div>
      </div>
      <div slot="body">
        <div>
          <h5>
            Serás redirigido a Expensas Pagas.<br /><br />
            Luego de realizar la transaccion, haz click en el botón de "Volver a I-data" para
            asentar tu pago en nuestra base de datos.
          </h5>
        </div>
      </div>
      <template slot="footer">
        <div>
          <button class="btn btn-rounded btn-primary text-center" @click="showModal = false; sendPay()">Redirigeme a Expensas Pagas</button>
        </div>
      </template>
    </default-modal>
    <default-modal v-if="showModalError" style="z-index: 10000">
      <div slot="header">
        <div class="col-12" style="padding: 2rem 1rem">
          <h4>Error</h4>
        </div>
      </div>
      <div slot="body">
        <div>
          <h5>
            Ocurio un error al realizar el pago<br /><br />
            Intenta nuevamente o comunicate con soporte.
          </h5>
        </div>
      </div>
      <template slot="footer">
        <div>
          <button class="btn btn-rounded btn-primary text-center" @click="closeModalError()">Cerrar</button>
        </div>
      </template>
    </default-modal>
    <div class="container bar-payment">
      <div class="card border-none shadow mb-4 mt-4">
        <div class="card-body">
          <p class="sub_title payment-space-p">Estos son los montos vigentes a pagar en este período</p>
          <table class="table table-payment-notification" style="background:#eef0f2;">
            <tr>
              <th class="functional-unit row-table-payment" style="padding-left: 2rem!important;padding-top: 5px!important;">Unidad funcional</th>
              <th class="amount row-table-payment" style="text-align: right;padding-right: 2rem!important;padding-top: 5px!important;">Monto</th>
            </tr>
            <tr
                    v-for="fu in owners_association.functional_units"
                    :key="fu.id"
                    :class="{ selected: selectedFunctionalUnitIds.includes(fu.id), 'other-row': !selectedFunctionalUnitIds.includes(fu.id) }"
                    @focus="toggleSelection(fu.id)"
                    @click="toggleSelection(fu.id)"
                    class="table-row"
            >
              <td class="row-payment" style="padding-left: .7rem!important;">
                <input type="checkbox" :value="fu.id" v-model="selectedFunctionalUnitIds" class="custom-checkbox mr-1" />
                {{ fu.location }}
              </td>
              <td class="row-payment" style="float:right;">
                <label class="field a-field a-field_a1">
                  <money class="field__input a-field__input" readonly v-model="inputComponent.amounts[fu.id]" v-bind="money" style="margin-bottom: 0" />
                </label>
              </td>
            </tr>
            <tr>
              <td scope="col" class="total">Total a pagar</td>
              <td scope="col" class="total_amount" style="text-align: right;">{{ total_amount | currency }}</td>
            </tr>
          </table>
          <div class="actions_payments">
            <button :disabled="loading||total_amount===0" :readonly="loading||total_amount===0" @click="openModal" class="btn btn-primary btn-rounded">
              <div v-if="loading" class="spinner-border spinner-border-sm ma-auto" role="status"></div>
              <span v-else>{{ textButton }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Capacitor } from '@capacitor/core';
  import HeaderBack from '@/components/Layouts/HeaderBack';
  import DefaultModal from '@/components/Modal/DefaultModal';

  export default {
    name: 'Pay',
    components: {
      HeaderBack,
      DefaultModal,
    },
    props: ['owners_association', 'input', 'loading', 'title', 'textButton', 'showModalError'],
    data() {
      return {
        selectedFunctionalUnitIds: [],
        showModal: false,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '$',
          precision: 2,
        },
        inputComponent: {
          payment_date: this.input.payment_date,
          amounts: this.input.amounts,
          amountsShow: this.input.amountsShow,
        },
      };
    },
    computed: {
      is_capacitor() {
        return Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android';
      },
      total_amount() {
        return this.selectedFunctionalUnitIds.reduce((total, id) => total + parseFloat(this.inputComponent.amounts[id] || 0), 0);
      },
    },
    methods: {
      toggleSelection(functionalUnitId) {
        const index = this.selectedFunctionalUnitIds.indexOf(functionalUnitId);
        if (index === -1) {
          this.selectedFunctionalUnitIds.push(functionalUnitId);
        } else {
          this.selectedFunctionalUnitIds.splice(index, 1);
        }
      },
      openModal() {
        if (!this.loading && this.total_amount !== 0) this.showModal = true;
      },
      closeModalError() {
        this.$emit('update:showModalError', false);
      },
      sendPay() {
        this.$emit('onPay', this.selectedFunctionalUnitIds, this.total_amount);
      },
    },
  };
</script>

<style lang="scss">
  .selected {
    background-color: #fce4ec; /* Color rosa claro de fondo cuando se selecciona la fila */
    color: #333; /* Color de texto cuando se selecciona la fila */
    border-radius: 10px; /* Borde redondeado para la fila seleccionada */
  }

  .other-row {
    background-color: #f9f9f9; /* Color de fondo de las filas no seleccionadas */
    color: #333; /* Color de texto de las filas no seleccionadas */
  }

  .table-row {
    border-radius: 10px; /* Borde redondeado para todas las filas */
  }

  .row-payment {
    vertical-align: middle !important; /* Centrar verticalmente el contenido */
  }

  input[type="checkbox"] {
    accent-color: #ce4169; /* Color del checkbox */
    width: 15px; /* Ancho del checkbox */
    height: 15px; /* Altura del checkbox */
    transform: scale(1.5); /* Escalar el checkbox */
  }

  .table {
    border-radius: 0.4rem;
  }

  #modal-2 .modal-body {
    height: 100% !important;
  }

  #modal-2 .modal-footer {
    justify-content: flex-end;
  }
</style>
