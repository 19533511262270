<template web>
    <div>
        <header-back title="VOLVER" url="/" />
        <div class="container bar-details velmld-parent">
            <!--    <vue-element-loading :active="isLoading" color="#CE4169" spinner="spinner"/>-->
            <div class="container bar-details">
                <div class="card border-box">
                    <div class="card-img-top" style="background-color: rgb(255 57 57); text-align: center; padding: 5%">
                        <div>
                            <img alt="" class="icon" src="@/assets/img/icons/error_circle_outline.png"
                                style="width: 64px;height: 64px;">
                            <h3 class="mt-4" style="color: whitesmoke">¡Ha ocurrido un error!</h3>
                            <h5 class="mt-4" style="color: whitesmoke">No se ha podido acreditar el pago.</h5>
                            <h5 class="mt-4" style="color: whitesmoke">Inténtalo nuevamente o comunícate con soporte.
                            </h5>
                            <h5 class="mt-4" style="color: whitesmoke">Serás redirigido en {{ timeleft }} segundos.</h5>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
    components: { HeaderBack },
    props: ['status', 'message'],
    data() {
        return {
            timerID: null,
            timeleft: 10,
            hash: localStorage.getItem('hash'),
            ownerAssociationId: localStorage.getItem('owners_association_id'),
        };
    },
    computed: {},
    methods: {
        redirector() {
            if (this.timeleft === 0) {
                clearInterval(this.timerID);
                this.timerID = null;
                this.timeleft = 10;
                const ownerAssociation = this.ownerAssociationId;
                localStorage.removeItem('hash');
                localStorage.removeItem('owners_association_id');
                if (this.ownerAssociationId) {
                    this.$router.push({
                        name: 'inicio',
                        params: { owners_association_id: ownerAssociation },
                    });
                } else {
                    this.$router.push({
                        name: 'consorcios',
                    });
                }
            } else {
                this.timeleft -= 1;
            }
        },
        async paymentReporter() {
            try {
                if (this.hash && this.ownerAssociationId) {
                    const result = await this.reportExpensasPagasPayment(this.ownerAssociationId, this.hash);
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
    mounted() {
        this.timerID = setInterval(this.redirector, 1000);
        this.paymentReporter();
    },
};
</script>

<style lang='scss'></style>
