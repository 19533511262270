<template>

  <div class="chat-messages">

    <div class="container">
      <vue-element-loading :active="isLoading" background-color="rgba(255, 255, 255, .0)" color="#CE4169"
                           spinner="spinner"/>
      <div v-for="(message, index) in messages"
           class="message clearfix"
           :key="index"
           v-bind:class="{ 'message-in': message.is_sent_by_administration, 'message-out': !message.is_sent_by_administration }"
           :id="'message-'+index">

        <div class="chat-timestamp text-muted" v-if="shouldDisplayDateSeparator(index)">
          <p class="text-center">{{message.created_at | moment('ll')}}</p>
        </div>

        <div class="message-avatar"></div>

        <div class="message-content">

          <div class="message-text" v-html="parseMessageBody(message.body)"></div>

          <!-- images -->
          <div class="msg-images" v-if="message.images.length && !is_capacitor">
            <a v-for="image in message.images" :key="image" :href="image.url" :data-lightbox="'message-'+index+'-images'" class="d-block mb-4 h-100" >
              <img class="img-fluid img-thumbnail" :src="image.thumbnail_xs" alt="">
            </a>
          </div>
          <div class="msg-images" v-if="message.images.length && is_capacitor">
            <a v-for="image in message.images" :key="image" :data-lightbox="'message-'+index+'-images'" class="d-block mb-4 h-100" @click="downloadFallback(image, 'img')">
              <img class="img-fluid img-thumbnail" :src="image.thumbnail_xs" alt="">
            </a>
          </div>

          <!-- attachments -->
          <div class="msg-attachments" v-if="message.attachments.length && !is_capacitor">
            <p v-for="attachment in message.attachments" :key="attachment">
              <a target="_blank" :href="attachment.url" >{{attachment.file_name}}</a></p>
          </div>

          <div class="msg-attachments" v-if="message.attachments.length && is_capacitor">
            <div  v-for="attachment in message.attachments" :key="attachment"  >
              <div class="btn" style="color: #ce4169;text-decoration: none;background-color: transparent;" @click="downloadFallback(attachment.url)">
                {{attachment.file_name}}
              </div>
            </div>
          </div>

          <div class="message-metadata">
            <span class="message-date">
              {{message.created_at | moment('HH:mm')}}
            </span>
            <span v-if="message.read_at && !message.is_sent_by_administration">
              Visto ✓
            </span>
          </div>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import axios from 'axios';
import { Dialog } from '@capacitor/dialog';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';

export default {
  props: ['messages'],
  data() {
    return {
      isLoading: false,
    };
  },
  watch:
      {
        messages:
            {
              immediate: true,
              handler() {
                setTimeout(() => {
                  if (!this.messages) {
                    return;
                  }

                  const lastMessage = `message-${this.messages.length - 1}`;
                  const msgElement = document.getElementById(lastMessage);

                  if (msgElement) {
                    msgElement.scrollIntoView();
                  }
                }, 100);
              },
            },
      },
  methods:
      {
        async writeAndPreviewPdf(base64Data, fileName) {
          await Filesystem.mkdir({ path: 'joinup', directory: Directory.Cache }).then(async () => {
            await Filesystem.writeFile({
              path: `joinup/${fileName.replace(' ', '-')}`,
              data: Buffer.from(base64Data).toString('base64'),
              directory: Directory.Cache,
            }).then(async (writedFile) => {
              this.localuri = writedFile.uri;
              console.log(`local uri  ${writedFile.uri}`);
            });
          }).catch(async () => {
            await Filesystem.writeFile({
              path: `joinup/${fileName.replace(' ', '-')}`,
              data: Buffer.from(base64Data).toString('base64'),
              directory: Directory.Cache,
            }).then(async (writedFile) => {
              this.localuri = writedFile.uri;
              console.log(`local uri  ${writedFile.uri}`);
            });
          });
        },
        async hasFilePermisions() {
          if (Capacitor.getPlatform() === 'ios') {
            return Filesystem.checkPermissions()
                    .then(async (result) => {
                      if ((`${result.publicStorage}` === 'prompt') || (`${result.publicStorage}` === 'denied') || (`${result.publicStorage}` === 'prompt-with-rationale')) {
                        return Filesystem.requestPermissions()
                                .then(async (publicStorage) => {
                                  const permissionsRequested = await publicStorage.publicStorage;
                                  console.log('prompt || denied || prompt-with-rationale');
                                  return `${permissionsRequested}` === 'granted';
                                });
                      }
                      return true;
                    });
          }
          return true;
        },
        async downloadFallback(doc, type = 'pdf') {
          this.loading = true;
          this.pdf = null;
          console.log('url:', doc);
          await this.hasFilePermisions().then(() => {
            axios.get(doc.url, {
              responseType: 'arraybuffer',
            }).then(async (success) => {
              this.hasFilePermisions().then(async (resultData) => {
              //  if (resultData) {
                  const arrayUrl = doc.url.split('/');
                  const fileName = arrayUrl[arrayUrl.length - 1];
                  const pdfData = await success.data;
                  this.isLoading = true;
                  await this.writeAndPreviewPdf(pdfData, fileName.replace(' ', '-'));
                  this.pdf = doc.url;
                  this.isLoading = false;
                  await this.$router.push({
                    name: 'pdf-view',
                    params: { url: this.pdf, localuri: this.localuri, docType: type },
                  });
             //   }
               /* else {
                  await Dialog.alert({
                    title: 'Información',
                    message: 'Denegaste los permisos de almacenamiento y esta operación no puede ser realizada',
                  });
                } */
              });
            });
          });
        },
        parseMessageBody(message) {
          if (!message) {
            return;
          }

          const parsedMessage = message
            .replace('<', '')
            .replace('>', '');

          if (typeof emojione === 'undefined') {
            // eslint-disable-next-line consistent-return
            return parsedMessage;
          }

          // eslint-disable-next-line no-undef,consistent-return
          return emojione.toImage(parsedMessage);
        },
        shouldDisplayDateSeparator(index) {
          if (!this.messages[index - 1]) {
            return true;
          }

          const previousMessageDate = new Date(this.messages[index - 1].created_at);
          const currentMessageDate = new Date(this.messages[index].created_at);

          return previousMessageDate.getDate() !== currentMessageDate.getDate();
        },
      },
  computed:
  {
   is_capacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },
    is_ios() {

      return (Capacitor.getPlatform() === 'ios');
    },
},
};
</script>
<style>
.ios
{

  padding-top: 1.7rem !important;
}
</style>
