<template web>
  <div>
<!--    <vue-element-loading v-if="getLoading" :active="getLoading" size="60" is-full-screen  spinner="spinner" background-color="rgba(0,0,0,0.74)" color="#CE4169"/>-->
    <MainNav v-if="this.$route.name!=='expenses_landing'
    && this.$route.name!=='pdf-view'
    && isActiveConnection
 //   && this.$route.name!=='consorcios'
    && this.$route.name!=='digital-expenses'
    && this.$route.name!=='success-message'
    && this.$route.name!=='error-message'
    && this.$route.name!=='ingresar'
    && this.$route.name!=='comprobantes_landing'
    && this.$route.name!=='documentos_landing'
    && this.$route.name!=='detalle-comprobante-landing'
    "
             :display="true"></MainNav>
    <div v-if="this.$route.params.owners_association_id">
      <owners-association-header
          v-if="owners_association && (
               this.$route.name!=='detalle-reserva'
              && this.$route.name!=='cuenta'
         && (this.$route.name!=='notificar-pago' || (this.$route.name==='notificar-pago' && is_capacitor === false))
          && this.$route.name!=='detalle-noticia'
          && this.$route.name!=='detalle-amenity'
          && this.$route.name!=='card-amenity'
          && this.$route.name!=='payment-error'
          && this.$route.name!=='payment-success'
          && this.$route.name!=='detalle-pago'
          && this.$route.name!=='reservar-amenity'
          && this.$route.name!=='listado-pagos'
 && this.$route.name!=='comprobantes_landing'
  && this.$route.name!=='documentos_landing'
 && this.$route.name!=='detalle-comprobante-landing'
          && this.$route.name!=='comprobantes'
         && this.$route.name!=='contacto'
          && this.$route.name!=='detalle-amenity'
          && this.$route.name!=='reservar-amenity'&& this.$route.name!=='detalle-reserva' && this.$route.name!=='detalle-comprobante')"
          :owners_association="owners_association"/>
      <transition name="fade" appear mode="out-in" >
        <router-view v-if="owners_association"/>
      </transition>
    </div>
    <div v-else>
      <header v-if="this.$route.name === 'consorcios' || (is_capacitor && (
          this.$route.name === 'cuenta' || this.$route.name === 'notificaciones' || this.$route.name.includes('mensajes')
      )) " :class="is_ios ? 'hed ios' : 'hed'"

      >
        
        <div >
          <section class="main">
            <div class="title">
              <img src="@/assets/img/idata-alt.svg" class="logo" alt="idata">
            </div>
            <div style="margin-left: auto;padding: 0.5rem;">
              <ul class="nav">
                <li class="nav-item nav-item-account">
                  <button type="button" class="btn btn-default sendRequestButton" @click="openModal">
                    <img src="@/assets/img/icons/icono-sugerencia.svg" class="icon" alt="">
                  </button>
                </li>
              </ul>
            </div>
          </section>
        </div>
        <send-request-modal></send-request-modal>
      </header>
      <transition name="fade" appear mode="out-in" >
        <router-view/>
      </transition>
    </div>
    </div>

</template>

<script>
import { Capacitor } from '@capacitor/core';
import MainNav from '@/components/Layouts/MainNav';
import OwnersAssociationHeader from '@/components/Layouts/OwnersAssociationHeader';
import SendRequestModal from '@/components/Layouts/sendRequest';
import store from '@/store';

export default {
  components: {
    MainNav,
    OwnersAssociationHeader,
    SendRequestModal,
  },
  name: 'default',
  props: [],
  data() {
    return {
      news: [],
    };
  },
  computed: {
    owners_association() {
      return this.$store.getters.ownersAssociation;
    },
    isActiveConnection() {
      return this.$store.getters.activeConnection;
    },
    is_ios() {

      return (Capacitor.getPlatform() === 'ios');
    },
    is_capacitor() {
      if ((Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android')) {
        return true;
      }
      return false;
    },
    
  },
  methods: {
    compararNews() {
      const firstLogin = localStorage.getItem('FirstLogin');
      if (firstLogin === '1') {
        const localNews = JSON.parse(localStorage.getItem('News')).map((p) => p.id);
        const serverNews = this.news.map((o) => o.id);
        const compare = this._.difference(serverNews, localNews);
        if (compare.length > 0) {
          this.$store.commit('new_notification', true);
        } else {
          this.$store.commit('new_notification', false);
        }
      } else {
        localStorage.setItem('FirstLogin', '1');
        const json = JSON.stringify(this.news);
        localStorage.setItem('News', json);
      }
    },
   async openModal() {

      // eslint-disable-next-line no-undef
      $('#sendRequestModal').appendTo('body').modal('show');
      await this.$store.commit('openRequestModal', true);
     console.log(this.$store.getters.openRequestModal);
     console.log('abrio');
    },
    async descargarDatos() {
      try {
        await this.getNews(this.$route.params.owners_association_id)
          .then((data) => {
            this.news = data;
            this.$store.dispatch('setNotLoading');
          }).catch(() => {
            this.$store.dispatch('setNotLoading');
          });
      } catch (e) {
        await this.$store.dispatch('setNotLoading');
      }
    },
  },
  async created() {
    await this.$store.dispatch('runFirstLoad');
    if (this.$route.params.owners_association_id) {
      await store.dispatch('setOwnersAssociation', Number(this.$route.params.owners_association_id));
      await this.descargarDatos();
      this.compararNews();
    }
  },
};
</script>

<style lang='scss'>
.fondoPage {
  background-color: #fafafa;
}

.title {
  @media (min-width: 576px) {
    display: none
  }
  
}

.logo {
  display: flex !important;
  margin-left: 1.1rem !important;
}
.header{
  position: sticky;
  top: 0;
  z-index: 2000;
}
.hed{
  position: sticky;
  top: 0;
  z-index: 10000;

}
.ios
{

  padding-top: 1.7rem !important;
}
</style>
