<template>

  <div class="page-account-view">
    <section class="bar theme-blue" >
      <div class="container">
        <div class="bar-avatar">
          <span><img src="@/assets/img/mi-cuenta.svg" class="img_min"></span>
        </div>
        <h3 class="bar-firm-name">
          Mi cuenta
        </h3>

      </div>
    </section>

    <div class="container bar-details">

      <div class="card border-none mb-4 mt-4 card-type">

        <div class="card-body">

          <!-- messages -->
          <div v-if="$route.params.success_message" class="alert alert-success">{{$route.params.success_message}}</div>
          <div v-if="$route.params.error_message" class="alert alert-danger">{{$route.params.error_message}}</div>

          <h5 class="title">Mi perfil</h5>
          <p class="sub_title">Esta es la información que tienes registrada en la plataforma, por favor asegúrate de mantener tus datos de contacto al día para que las comunicaciones sean efectivas.</p>

          <table class="table text-table-details">
            <tr>
              <td>Nombre</td>
              <td>{{user.first_name}}</td>
            </tr>
            <tr>
              <td>Apellido</td>
              <td>{{user.last_name}}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{user.email}}</td>
            </tr>
            <tr>
              <td>Teléfono</td>
              <td>{{user.phone}}</td>
            </tr>
          </table>
          <div class="max-45"></div>
          <router-link to="/cuenta/editar-perfil" class="btn btn-primary btn-rounded">Editar perfil</router-link>
        </div>
      </div>

      <div class="card border-none mb-4 card-type">
        <div class="card-body">
          <h5 class="title">Inicio de sesión y seguridad</h5>
          <p class="sub_title">Desde aquí podrás cambiar tu contraseña.</p>
          <div class="max-50"></div>
          <router-link to="/cuenta/cambiar-clave" class="btn btn-outline-primary btn-rounded">Cambiar contraseña</router-link>
        </div>
      </div>

      <div class="card border-none mb-4 card-type">
        <div class="card-body">
          <h5 class="title">Cerrar sesión</h5>
          <p class="sub_title">Haz clic en salir para cerrar la sesión en este dispositivo.</p>
          <div class="max-50"></div>
          <button @click="doLogout" class="btn btn-outline-primary btn-rounded">Salir</button>
        </div>
      </div>
      <div v-if="is_capacitor">
        Version: <small>{{versionCapacitor}}{{environment=="development" ? 'D' : ''}}</small>
      </div>

    </div>
  </div>

</template>

<script>

import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import OwnersAssociationHeader from '@/components/Layouts/OwnersAssociationHeader';
import store from '@/store';

export default
{
  components:
      {
        OwnersAssociationHeader,

      },
  data() {
    return {
      user: {},
      delete_token: {},
      versionCapacitor: null,
      build: null,
      environment: process.env.NODE_ENV,

    };
  },
  methods:
      {
        async getVersion() {
          this.versionCapacitor = await App.getInfo().then(result => result.version);
        },
        async getBuild() {
          this.build = await App.getInfo().then(result => result.build);
        },

        doLogout() {
          this.signOut();

          this.$router.push(
            {
              name: 'ingresar',
              params:
                    {
                      success_message: 'La sesión se cerro satisfactoriamente',
                    },
            },
          );

          // location.reload();
        },
      },
  computed:
      {
        is_capacitor() {
          return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
        },
        version() {
          return process.env.VUE_APP_VERSION;
        },
      },
  async created() {
    store.dispatch('setLoading');
    this.user = await this.getProfile();
    store.dispatch('setNotLoading');
    this.getVersion();
    this.getBuild();

    /* if (process.env.NODE_ENV !== 'mobile') {
      this.$fbmessaging.requestPermission()
        .then(() => this.$fbmessaging.getToken())
        .then((token) => {
          console.log(token);
          this.delete_token = token;
        })
        .catch((err) => err);
    } */
  },

};
</script>
