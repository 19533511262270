<template>
 <PaymentNotification :payment_methods="payment_methods"
                      :input="input"
                      :owners_association="owners_association"
                      :is-enabled="isEnabled"
                      :total_amount="total_amount"
                      :total_amountShow="total_amountShow"
                      :loading="loading"
                      :hasPhoto="hasPhoto"
                      :photo="photo"
                      :form="form"
                      :is_capacitor="is_capacitor"
                      @onshowSettlements="showSettlements"
                      @onUploaderChange="onUploaderChange"
                      @takePhoto="takePhoto"
                      @removePhoto="removePhoto"
                      @onhandleAmount="handleAmount"
                      @onsendPaymentNotification="sendPaymentNotification"
 ></PaymentNotification>
</template>
<script>

import { Camera, CameraResultType } from '@capacitor/camera';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import '@ionic/pwa-elements';
import { Capacitor } from '@capacitor/core';
import OwnersAssociationHeader from '@/components/Layouts/OwnersAssociationHeader';
import PaymentNotification from '@/components/OwnersAssociation/PaymentNotification/PaymentNotification';

export default {
  components:
      {
        PaymentNotification,
        OwnersAssociationHeader,
      },
  data() {
    return {
      events: [],
      tasks: [],
      loading: false,
      hasPhoto: false,
      fileToUpload: null,
      attachment: null,
      photo: false,
      settlements: [],
      period: '',
      typeFile: '',
      owner: '',
      total_amount: 0,
      total_amountShow: 0,
      hideDate: false,
      typeInput: true,
      form:
          {
            success: null,
            error: null,
          },
      input:
          {
            payment_date: '',
            payment_method: '',
            payment_number: '',
            payment_commentary: '',
            bank_account: '',
            amounts: {},
            amountsShow: {},
          },
      payment_methods: [],
      bank_accounts: [],
    };
  },
  async created() {
    this.loading = true;

        if (!this.owners_association.bank_accounts || this.owners_association.bank_accounts.length === 0) {
      return;
    }
    if (this.owners_association.bank_accounts) {
      this.input.bank_account = this.owners_association.bank_accounts[0].id;
      this.input.payment_method = 1;
    }
    this.getPaymentMethods().then((methods) => {
      this.payment_methods = methods;
    });
    if (this.owners_association.periods) {
      this.period = this.owners_association.periods[this.owners_association.periods.length - 1].id;
      this.owner = this.owners_association.id;
      await this.setSettlements(this.period, this.owner);
    }
    this.loading = false;

    // eslint-disable-next-line no-undef

  },
  computed:
      {
        isEnabled() {
          return this.$store.getters.ownersAssociation.bank_accounts && this.$store.getters.ownersAssociation.bank_accounts.length > 0;
        },
        owners_association() {
          return this.$store.getters.ownersAssociation;
        },
        is_capacitor() {
          return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
        },
      },

  methods:
      {
        showSettlements() {
          // Set settlements by date
          let total = 0;
          const fu_ids = Object.keys(this.input.amountsShow);
          for (let i = 0; i < fu_ids.length; i += 1) {
            if (this.$moment.utc(this.input.payment_date).format('Y-MM-DD') <= this.$moment.utc(this.settlements[i].first_due_date).format('Y-MM-DD')) {
              total += this.settlements[i].amount_on_first_due_date;
              this.input.amountsShow[fu_ids[i]] = this.settlements[i].amount_on_first_due_date;
            } else {
              total += this.settlements[i].amount;
              this.input.amountsShow[fu_ids[i]] = this.settlements[i].amount;
            }
          }
          this.total_amountShow = total;
        },
        async setSettlements(period, owner) {
          try {
            // Set settlements
            this.settlements = await this.getSettlements(owner, period);
            let total = 0;
            const fu_ids = this.owners_association.functional_units.map((p) => p.id);
            let payment = false;
            for (let i = 0; i < fu_ids.length; i += 1) {
              total += this.settlements[i].amount;
              this.input.amountsShow[fu_ids[i]] = this.settlements[i].amount;
              this.input.amounts[fu_ids[i]] = 0;
              payment = this.settlements[i].payments;
            }
            this.total_amount = 0;
            this.total_amountShow = total;
            if (payment) {
              this.$swal({
                icon: 'warning',
                title: 'Tienes informado un pago de este período',
                text: '¿Aún así quieres continuar?',
                confirmButtonText: 'Entendido',
              });
            }
          } catch (err) {
            console.log(err);
          }
        },
       async onUploaderChange(e) {
 const file = e.target.files[0];
          if (process.env.VUE_APP_MODE === 'web') {
            [this.fileToUpload] = e.target.files;

            if (this.fileToUpload) {
              if (['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].indexOf(this.fileToUpload.type) === -1) {
                this.$swal({
                  icon: 'error',
                  title: 'Ocurrió un error',
                  text: 'Recuerda cargar el tipo de archivo permitido!',
                });

                document.getElementById('info').innerHTML = 'Selecciona el archivo';
                this.fileToUpload = {};
              } else {
                const pdrs = this.fileToUpload.name;
                this.typeFile = this.fileToUpload.type;
                document.getElementById('info').innerHTML = pdrs;
              }
            }
          } else if (process.env.VUE_APP_MODE === 'native') {
            this.fileToUpload = e;
          }
          let type;
          if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/jpg') type = 1;
          if (e.target.files[0].type === 'document/pdf') type = 2;

          let dataUrl; let arraybuffer;
          await this.readFile(e.target.files[0], 1).then(result => {
            console.log(`result:${JSON.stringify(result)}`);
            console.log(`result:${result}`);
            dataUrl = result;
          });
          await this.readFile(e.target.files[0], 2).then(result => {
            console.log(`result:${JSON.stringify(result)}`);
            console.log(`result:${result}`);
            arraybuffer = result;
          });
          this.attachment = {
            name: file.name, type, file: dataUrl, arrayBuffer: arraybuffer,
          };
        },
        readFile(file, type) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onend = reject;
            reader.onabort = reject;
            reader.onload = () => resolve(reader.result);
            if (type === 1) {
              reader.readAsDataURL(new Blob([file], {
                type: file.type,
              }));
            } else
            if (type === 2) {
              reader.readAsArrayBuffer(new Blob([file], {
                type: file.type,
              }));
            }
          });
        },
        startUpload(imagen) {
          const name = imagen.substr(imagen.lastIndexOf('/') + 1);

          const { token } = this.$store.state;
          const request = {
            url: 'https://apipower.i-data.com.ar/sysusers/services/suite/copropietarios/uploadFiles',
            method: 'POST',
            headers: {
              Authorization: token,
              'Content-Type': 'application/octet-stream',
              'File-Name': name,
            },
            description: `Uploading ${name}`,
            androidAutoDeleteAfterUpload: false,
            androidNotificationTitle: `Uploading ${name}`,
          };
          const task = this.session.uploadFile(imagen, request);
          function onEvent(e) {
            this.events.push({
              eventTitle: `${e.eventName} ${e.object.description}`,
              eventData: JSON.stringify({
                error: e.error ? e.error.toString() : e.error,
                currentBytes: e.currentBytes,
                totalBytes: e.totalBytes,
                body: e.data,
                responseCode: e.responseCode,
              }),
            });
            this.$set(this.tasks, this.tasks.indexOf(task), task);
          }
          task.on('progress', onEvent.bind(this));
          task.on('error', onEvent.bind(this));
          task.on('responded', onEvent.bind(this));
          task.on('complete', onEvent.bind(this));
          this.tasks.push(task);
        },
        handleAmount(inputComponent) {
          let total = 0;
          this.input.amounts = inputComponent.amounts;
          const fu_ids = Object.keys(this.input.amounts);
          for (let i = 0; i < fu_ids.length; i += 1) {
            if (parseFloat(this.input.amounts[fu_ids[i]]) > 0) {
              const n = parseFloat(this.input.amounts[fu_ids[i]]);
              total += n;
            } else {
              this.input.amounts[fu_ids[i]] = 0;
            }
          }
          this.total_amount = total;
        },
        async takePhoto() {
          const hasPermission = await Camera.checkPermissions();
          console.log(`hasPermission ${hasPermission.camera}`);
          if (hasPermission.camera !== 'granted') {
            await Camera.requestPermissions();
          } else {
            try {
              const profilePicture = Camera.getPhoto({
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.DataUrl,
                source: 'CAMERA',
              });

              this.photo = (await profilePicture).dataUrl;
                const base64ContentArray = this.photo.split(',');

                const mimetype = base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];

                fetch(this.photo)
                    .then(res => res.blob())
                    .then(async blob => {
                      const file = blob;
                        console.log('here is your binary: ', blob);
                        this.fileToUpload = blob;
                        this.hasPhoto = true;
                      let dataUrl; let arraybuffer;
                      const type = 'image/jpeg';
                        await this.readFile(blob, 1).then(result => {
                          console.log(`result:${JSON.stringify(result)}`);
                          console.log(`result:${result}`);
                          dataUrl = result;
                        });
                      await this.readFile(blob, 2).then(result => {
                        console.log(`result:${JSON.stringify(result)}`);
                        console.log(`result:${result}`);
                        arraybuffer = result;
                      });
                      this.attachment = {
                        name: 'photo.jpg', type: 1, file: dataUrl, arrayBuffer: arraybuffer,
                      };

                    });

            } catch (error) {
              console.error(error);
            }
            // image.webPath will contain a path that can be set as an image src.
            // You can access the original file using image.path, which can be
            // passed to the Filesystem API to read the raw data of the image,
            // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
            //    const imageUrl = image.webPath;
            // Can be set to the src of an image now
            // imageElement.src = imageUrl;
          }
        },

        removePhoto() {
          this.hasPhoto = false;
          this.photo = false;
          this.attachment = null;
        },

        beforeUpload(file) {
          const fileName = file.name || '';

          this.getBase64(file).then(res => {
            // console.log(res);

            // Binary array conversion
            const bytes = window.atob(res.split(',')[1]); // Remove the header of the url and convert it to byte

            // Handle exceptions and convert the ascii code less than 0 to greater than 0
            const ab = new ArrayBuffer(bytes.length);
            const ia = new Uint8Array(ab);
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < bytes.length; i++) {
              ia[i] = bytes.charCodeAt(i);
            }

            console.log('ia', ia);
          });
        },

       async sendPaymentNotification(inputComponent) {
          this.loading = true;
          this.input = inputComponent;
         const attachementsData = [];
           
          if (this.input.payment_date === '') {
            this.$swal({
              icon: 'error',
              title: 'Ocurrió un error',
              text: 'Debes ingresar la fecha del pago!',
            });
            this.loading = false;
            return;
          }

          if (this.total_amount === 0) {
            this.$swal({
              icon: 'error',
              title: 'Ocurrió un error',
              text: 'Debes ingresar el pago de al menos una unidad funcional!',
            });
            this.loading = false;
            return;
          }

          let paydate = new Date(this.input.payment_date);
          paydate = paydate.toISOString();
          const amounts = Object.keys(this.input.amounts)

            .map((amount) => ({
              functional_unit_id: amount,
              amount: this.input.amounts[amount],
            }));

          if (amounts.length === 0) {
            this.form.error = 'Debes ingresar el pago de al menos una unidad funcional';
            this.loading = false;
            return;
          }
         if (this.attachment != null) {
           attachementsData.push({ file: this.attachment.file, file_type: this.attachment.name.split('.').pop() });
                   }
          const payment_data = {
            bank_account_id: this.input.bank_account,
            payment_method_id: this.input.payment_method,
            date: paydate,
            number: this.input.payment_number,
            period: this.period,
            commentary: this.input.payment_commentary,
            amounts,
            attachments: attachementsData,

          };
console.log(JSON.stringify(attachementsData));
          await this.notifyPayment(payment_data)
            .then(async (res) => {
              this.loading = false;
              this.$swal({
                icon: 'success',
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: 'Aceptar',
                title: 'Notificación Recibida',
                html: '¡La notificación de pago se recibió correctamente! <br><br>La misma está en espera de la conciliación correspondiente',
              })
                  .then((result) => {
                    if (result.value) {
                      this.$router.push(
                          {
                            name: 'expensas',
                            params:
                                {
                                  success_message: '¡La notificación de pago se recibió correctamente! ',
                                },
                          },
                      );
                    }
                  });
              this.form.success = true;
            })
            .catch((res) => {
              console.log(res);
              this.loading = false;
              if (this.hideDate) {
                this.$swal({
                  icon: 'error',
                  title: 'Ocurrió un error',
                  text: 'Debes ingresar el pago de al menos una unidad funcional!',
                });
              }

              if (this.input.payment_date === '') {
                this.$swal({
                  icon: 'error',
                  title: 'Ocurrió un error',
                  text: 'Debes ingresar la fecha del pago!',
                });
              }

              if (this.total_amount === 0) {
                this.$swal({
                  icon: 'error',
                  title: 'Ocurrió un error',
                  text: 'Debes ingresar el pago de al menos una unidad funcional!',
                });
              }
              // this.form.error   = 'Ocurrió un error al enviar la notificación de pago, intente nuevamente mas tarde'
            });
        },
      },
};

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
