import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { Directory, Filesystem } from '@capacitor/filesystem';
import axios from 'axios';
import { Dialog } from '@capacitor/dialog';
import _ from 'lodash';
import moment from 'moment';
import CoreService from '@/mixins/CoreService';
import NotificationService from '@/mixins/NotificationService';
import store from '@/store';
import auth from '@/mixins/auth';
import router from '../routes/router';
import CapacitorFileHelper from '@/mixins/CapacitorFileHelper';

const actions = {
  runFirstLoad(context) {
    return new Promise(async (resolve, reject) => {
      // context.dispatch('handlePushNotifications');
      try {
          const userjson = localStorage.getItem('User');
          const token = localStorage.getItem('AccessToken');
          if (!userjson) {
              await auth.methods.getUser(token);
          }

          await context.dispatch('setUser');
        if (store.getters.user.token && store.getters.user.IdToken) {
          console.log(store.getters.user.IdToken);
          await context.dispatch('refreshToken').then(async () => {
            await context.dispatch('setUser').then(async () => {
              await context.dispatch('fetchNotifications');
            });
          });
          // TODO: Cambiar este despacho de nuevo usuario por el token de refresco
          await context.dispatch('setOwnersAssociations');
          await context.dispatch('evaluateOwnersAssociations');
          await context.dispatch('fetchMessages');
          await context.dispatch('suscribeFCMPushTokens');
        }
        resolve(true);
      } catch (e) {
        reject(e);
      }
      /*  context.dispatch('fetchMessages');
        // Fetch messages every minute

        setInterval(() => {
          context.dispatch('fetchMessages');
          context.dispatch('fetchAmenitiesBookings');
          // context.dispatch('fetchNotifications');
        }
        , (process.env.VUE_APP_BACKGROUND_ACTIONS_REFRESH * 1000)); */
    });
  },
  async suscribeFCMPushTokens(context) {
    if ((Capacitor.getPlatform() === 'android') || (Capacitor.getPlatform() === 'ios')) {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          PushNotifications.register();
        }
      });
      // Add registration error if there are.
      PushNotifications.addListener('registrationError', (error) => {
        console.log(`error on register ${JSON.stringify(error)}`);
      }),
          // Add Notification received
          PushNotifications.addListener(
              'pushNotificationReceived',
              async (notification) => {
                console.log(`notification ${JSON.stringify(notification)}`);
              },
          ),
          // Add Action performed
          // Add Action performed
          PushNotifications.addListener(
              'pushNotificationActionPerformed',
              async (notification) => {
                  let isSettlement = false;
                  let isDocument = false;
                  let isNews = false;
                  let isNotification = false;
                  let isBooking = false;

                    console.log(JSON.stringify(notification.notification));
                  const { title } = await notification.notification.data;
                  if (title.includes('Su solicitud de reserva')) {
                      isBooking = true;
                      console.log('es reserva');
                  }
                  if (title.includes('Nueva publicación de expensas')) {
                      isSettlement = true;
                  }
                  if (title.includes('Nuevo Documento')) {
                      isDocument = true;
                  }
                  if (title.toLowerCase().includes('notificación de pago')) {
                      isNotification = true;
                  }

                  if (title.includes('Nueva Noticia')) {
                      isNews = true;
                  }
                  if (isNews && !isDocument && !isSettlement && !isNotification && !isBooking) {
                      await router.push({
                          name: 'detalle-noticia',
                          params: {
                              id: notification.notification.data.news,
                              owners_association_id: notification.notification.data.owners_association,
                          },
                      });
                  }

                  if (isNotification && !isNews && !isDocument && !isSettlement && !isBooking) {
                      await router.push({
                          name: 'detalle-pago',
                          params: {
                              id: notification.notification.data.news,
                              owners_association_id: notification.notification.data.owners_association,
                          },
                      });
                  }
                  if (isBooking && !isNews && !isDocument && !isSettlement && !isNotification) {
                      console.log('es reservax');

                      const parent = this;
                      let selected;
                      let bookingDate;
                      let selectedAmenity;
                      await context.dispatch('setOwnersAssociation', notification.notification.data.owners_association);
                      console.log('entro por aca');
                      await context.dispatch('fetchAmenitiesBookings', notification.notification.data.owners_association)
                          .then(async () => {
                              console.log('entro por aca 2 ');
                              const bookings = store.getters.amenitiesBookings;
                              const amenities = store.getters;
console.log(JSON.stringify(bookings));
                              bookings.map((booking) => {
                                  const amenityId = JSON.parse(Object.keys(booking));
                                  const slotsDate = JSON.parse(JSON.stringify(booking[Object.keys(booking)[0]]));
                                  slotsDate.forEach((checkBelongsToUser) => {
                                      const datekey = (Object.keys(checkBelongsToUser));
                                      checkBelongsToUser[datekey.toString()].forEach((bookData) => {
                                          const currentKeyDate = _.first(datekey);

                                          console.log(`entro ${JSON.stringify(bookData)}`);

                                          if (bookData.booking_id == notification.notification.data.news) {
                                              selected = bookData;
                                              selectedAmenity = amenityId;
                                              bookingDate = moment(currentKeyDate);
                                              // eslint-disable-next-line consistent-return

                                          }
                                      });
                                  });
                                  return selected;
                              });
                              console.log(`selected ${JSON.stringify(selected)}`);

                              console.log(`selected ${JSON.stringify(selectedAmenity)}`);

                          });
                      console.log(`fetch ${selectedAmenity}`);

                      await CoreService.methods.getAmenity(selectedAmenity).then(async (amenity) => {
                          console.log(`amemity ${JSON.stringify(amenity)}`);
                           const newObject = {
                              ...selected,
                              image: amenity.amenity_data.image_url,
                              title: amenity.name,
                              cancel_reason: selected.cancel_reason,
                              is_cancelled: selected.is_cancelled,
                              is_bookable: amenity.is_bookable,
                              type: amenity.type.name,
                              date: bookingDate.format('DD-MM-Y'),
                              amenity_id: amenity.id,
                          };

                          console.log(`newObject ${JSON.stringify(newObject)}`);
                          await router.push(
                              {
                                  name: 'detalle-reserva',
                                  params: { booking: newObject },
                              },
                          );

                      });

                  }

                  if (isSettlement && !isDocument && !isNews && !isNotification && !isBooking) {
                      await store.dispatch('setOwnersAssociation', Number(notification.notification.data.news.substring(0, 8))).then(async (data) => {
                          await router.push(`/consorcios/${notification.notification.data.news.substring(0, 8)}`);
                      });
                  }
                  if (isDocument && !isSettlement && !isNews && !isNotification && !isBooking) {
                      // TODO: redireccionar a documento individual
                      await CapacitorFileHelper.methods.downloadFallback(notification.notification.data);

                  }

              },
          );
    }

    if (await (Capacitor.getPlatform() === 'android')) {
      PushNotifications.addListener('registration', async (token) => {
        console.log(`Push registration success, token: ${token.value}`);
        if (await store.getters.user.token && await store.getters.user.IdToken) {
          const responseValue = await CoreService.methods.subscribePush(token.value);
          console.log(JSON.stringify(responseValue));
        }
      });
    }
    if (await (Capacitor.getPlatform() === 'ios')) {
      PushNotifications.requestPermissions().then((permission) => {
        if (permission.receive === 'granted') {
          PushNotifications.addListener('registration', async ({ value }) => {
            const token = value; // Push token for Android
            if (await store.getters.user.token && await store.getters.user.IdToken) {
              // Get FCM token instead the APN one returned by Capacitor
              await FCM.getToken()
                  .then(async (r) => {
                    await CoreService.methods.subscribePush(r.token);
                  }).catch((err) => console.log(err));
            }
            console.log(token);
          });
        }
      });
    }

  },

    async subscribeWebPushNotifications(context, token) {
    try {
      if ((Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android')) {
        return;
      }
      const tokens = await CoreService.methods.getPushTokens();

      if (tokens.indexOf(token) === -1) {
        await CoreService.methods.subscribePush(token);
      }
    } catch (err) {
      console.log('Push notifications error: ', err);
    }
  },
  /* async handlePushNotifications(context) {
    try {
      const tokens = await CoreService.methods.getPushTokens();
    } catch (err) {
      console.log('Push notifications error: ', err);
    }
  }, */
  setUserAsNew(context) {
    context.commit('newUser', 'y');
  },
  setTemporalPassword(context, password) {
    context.commit('temporalPassword', password);
  },
  setActiveConnection(context, value) {
    context.commit('activeConnection', value);
  },
  setOpenRequestModal(context, value) {
    context.commit('openRequestModal', value);
  },

  setEmail(context, mail) {
    context.commit('email', mail);
  },
  setUserAsNotNew(context) {
    context.commit('newUser', 'n');
  },
  setLoading(context, label) {
    context.commit('loading', true);
    context.commit('loadingText', label);
  },
  setNotLoading(context) {
    context.commit('loading', false);
    context.commit('loadingText', '');
  },
  async fetchNotifications(context) {

    await NotificationService.methods.getNotifications()
      .then(
        (data) => {
          context.commit('notifications', data);

        },
      )
      .catch((err) => {
        console.log(err);
      });
  },
  async refreshToken() {
    await auth.methods.refreshToken().then((data) => {
      console.log(data);
    }).catch(async (err) => {
      console.log(err);
       await auth.methods.signOut();

          await router.push({
            name: 'ingresar',
            params:
                {
                  error_message: 'Su sesión ha expirado por favor inicie sesión nuevamente',
                },

          });
    });
  },
  async fetchMessages(context) {
    try {
      const messagesList = await CoreService.methods.getMessages();
      const messages = [];

      let administrations = this.getters.ownersAssociations.map((o) => o.administration);
      // Delete duplicates

      const unique = new Set(administrations.map((e) => JSON.stringify(e)));
      administrations = Array.from(unique).map((e) => JSON.parse(e));
      // Revisar si se puede mejorar el for
      for (let i = 0; i < administrations.length; i += 1) {
        /*
                Recorremos las administraciones y nos fijamos en el arreglo messagesList
                si existe la administración actual, en caso de que si, devolvemos ese objeto
                En caso de que no, el objeto de administración solo.
                Posteriormente evaluamos 'last_message', si existe, mostramos el mensaje.
                De lo contrario, mostramos el div para iniciar la conversación
                */

        const messagesByAdministration = messagesList.filter((m) => m.administration.id === administrations[i].id);

        if (messagesByAdministration.length > 0) {
          messages.push(messagesByAdministration[0]);
        }

        messages.push(
          {
            administration: administrations[i],
          },
        );
      }

      context.commit('messages', messages);
    } catch (err) {
      console.log(err);
    }
  },
  async setUser(context) {
    const userjson = localStorage.getItem('User');
      const token = localStorage.getItem('AccessToken');
    if (userjson) {
      const user = JSON.parse(userjson);
      console.log('user:', user);

      const IdToken = localStorage.getItem('IdToken');
      const refreshToken = localStorage.getItem('RefreshToken');

      context.commit('user',
        {
          username: user.data.username,
          email: user.data.email,
          token,
          IdToken,
          refreshToken,
        });

      context.commit('logged', true);
    } else {
        await auth.methods.getUser(token);
       context.commit('user',
        {

          username: null,
          email: null,
          token: null,
          IdToken: null,
          refreshToken: null,
        });
      context.commit('logged', false);
    }
  },
  evaluateOwnersAssociations: (context) => new Promise((resolve, reject) => {
    try {
      context.commit('hasMultiplesOwnersAssociations', context.getters.ownersAssociations.length > 1);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  }),
  setOwnersAssociations: (context) => new Promise((resolve, reject) => {
    CoreService.methods.getOwnersAssociations().then((res) => {
      context.commit('ownersAssociations', res);
      if (res.length === 0) {
        context.dispatch('setUserAsNew');
      }
      resolve(true);
    }).catch((e) => { reject(e); });
  }),
  setOwnersAssociation: (context, id) => new Promise((resolve, reject) => {
    CoreService.methods.getOwnersAssociation(id)
      .then((data) => {
        context.commit('ownersAssociation', data);
        context.dispatch('setAdministrationFeatures', data.administration.id);
        store.dispatch('fetchAmenities', id);
        store.dispatch('fetchAmenitiesBookings', id);
        resolve(data);
      })
      .catch(
        (err) => {
          reject(err);
        },
      );
  }),
  deleteOwnersAssociation(context) {
    context.commit('ownersAssociation', null);
  },
  async setAdministrationFeatures(context, id) {
    await CoreService.methods.getAdministrationFeatures(id)
      .then((data) => {
        context.commit('administrationFeatures', data);
      }).catch(
        (err) => {
          console.log(err);
        },
      );
  },
  async fetchAmenity(context, amenityId) {
    if (!amenityId) {
      amenityId = context.getters.amenity.id;
    }
    await CoreService.methods.getAmenity(amenityId)
      .then((data) => {
        context.commit('amenity', data);
      }).catch(
        (err) => {
          console.log(err);
        },
      );
  },
  async fetchAmenities(context, ownersAssociationId) {
    if (!ownersAssociationId) {
      ownersAssociationId = context.getters.ownersAssociation.id;
    }
    await CoreService.methods.getAmenities(ownersAssociationId)
      .then((data) => {
          console.log(`amenities: ${JSON.stringify(data)}`);
          console.log(data.hasAvailableBookings);
        context.commit('amenities', data);
      }).catch(
        (err) => {
          console.log(err);
        },
      );
  },
  async fetchAmenitiesBookings(context, ownersAssociationId) {
    if (!ownersAssociationId) {
      ownersAssociationId = context.getters.ownersAssociation.id;
    }
    await CoreService.methods.getAmenityBookings(ownersAssociationId)
      .then((data) => {

        context.commit('amenitiesBookings', data);
      }).catch(
        (err) => {
          console.log(err);
        },
      );
  },

};

export default actions;
