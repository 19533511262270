var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.$route.name!=='expenses_landing'
    && this.$route.name!=='pdf-view'
    && _vm.isActiveConnection
 //   && this.$route.name!=='consorcios'
    && this.$route.name!=='digital-expenses'
    && this.$route.name!=='success-message'
    && this.$route.name!=='error-message'
    && this.$route.name!=='ingresar'
    && this.$route.name!=='comprobantes_landing'
    && this.$route.name!=='documentos_landing'
    && this.$route.name!=='detalle-comprobante-landing'
    )?_c('MainNav',{attrs:{"display":true}}):_vm._e(),(this.$route.params.owners_association_id)?_c('div',[(_vm.owners_association && (
               this.$route.name!=='detalle-reserva'
              && this.$route.name!=='cuenta'
         && (this.$route.name!=='notificar-pago' || (this.$route.name==='notificar-pago' && _vm.is_capacitor === false))
          && this.$route.name!=='detalle-noticia'
          && this.$route.name!=='detalle-amenity'
          && this.$route.name!=='card-amenity'
          && this.$route.name!=='payment-error'
          && this.$route.name!=='payment-success'
          && this.$route.name!=='detalle-pago'
          && this.$route.name!=='reservar-amenity'
          && this.$route.name!=='listado-pagos'
 && this.$route.name!=='comprobantes_landing'
  && this.$route.name!=='documentos_landing'
 && this.$route.name!=='detalle-comprobante-landing'
          && this.$route.name!=='comprobantes'
         && this.$route.name!=='contacto'
          && this.$route.name!=='detalle-amenity'
          && this.$route.name!=='reservar-amenity'&& this.$route.name!=='detalle-reserva' && this.$route.name!=='detalle-comprobante'))?_c('owners-association-header',{attrs:{"owners_association":_vm.owners_association}}):_vm._e(),_c('transition',{attrs:{"name":"fade","appear":"","mode":"out-in"}},[(_vm.owners_association)?_c('router-view'):_vm._e()],1)],1):_c('div',[(this.$route.name === 'consorcios' || (_vm.is_capacitor && (
          this.$route.name === 'cuenta' || this.$route.name === 'notificaciones' || this.$route.name.includes('mensajes')
      )) )?_c('header',{class:_vm.is_ios ? 'hed ios' : 'hed'},[_c('div',[_c('section',{staticClass:"main"},[_vm._m(0),_c('div',{staticStyle:{"margin-left":"auto","padding":"0.5rem"}},[_c('ul',{staticClass:"nav"},[_c('li',{staticClass:"nav-item nav-item-account"},[_c('button',{staticClass:"btn btn-default sendRequestButton",attrs:{"type":"button"},on:{"click":_vm.openModal}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/icons/icono-sugerencia.svg"),"alt":""}})])])])])])]),_c('send-request-modal')],1):_vm._e(),_c('transition',{attrs:{"name":"fade","appear":"","mode":"out-in"}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/idata-alt.svg"),"alt":"idata"}})])}]

export { render, staticRenderFns }